<template>
	<div class="layindex">
		<v-telephone></v-telephone>
		<img class="logoname" src="@/assets/logoname.png" alt="">
		<div class="minebox">
			<div class="userinfo">
				<div class="refunddes" @click="goexplain">
					<img class="icon" src="@/assets/icon-tips.png" alt="">退款说明
				</div>
				<div class="useritem">
					<div class="itembox" style="flex: 1.2;">
						<div class="userpic">
							<img :src="userInfo.avatar ? userInfo.avatar : defaultpic" alt="">
						</div>
						<div class="userid">ID:{{ userInfo.id }}</div>
					</div>
					<div class="itembox">
						<div class="btnbox" @click="gorecharge">充值</div>
						<div class="btnbox" @click="refundApply">退款</div>
					</div>
				</div>
				<div class="moneybox">
					<div class="item">
						<div class="moneynum">{{ userInfo.vip_money }}</div>
						<div class="moneydes">会员余额</div>
					</div>
					<div class="item">
						<div class="moneynum">{{ userInfo.fit_money }}</div>
						<div class="moneydes">散客余额</div>
					</div>
					<div class="item">
						<div class="moneynum">{{ userInfo.integral }}</div>
						<div class="moneydes">可用积分</div>
					</div>
				</div>
			</div>
			<ul class="listul">
				<li v-for="(item, index) in menulist" :key="index" @click="gonext(item)">
					<img class="icon" src="@/assets/icon-list.png" alt="">
					{{ item.name }}
					<img class="iconarrow" src="@/assets/icon-arrowright.png" alt="">
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import telephone from '@/components/telephone.vue'
export default {
	name: 'mine',
	components: {
		'v-telephone': telephone,
	},
	created () {
		this.getSystemInfo()
		this.getUserinfo()
	},
	mounted () {
		if (this.$store.state.userInfo.id > 0) {
			this.$utils.getWxJsConfig()
		}
	},
	data () {
		return {
			menulist: [
				{
					name: '消费记录',
					path: '/spendlist'
				},
				{
					name: '积分变动记录',
					path: '/integrallist'
				},
				{
					name: '充值记录',
					path: '/rechargelist'
				},
				{
					name: '退款记录',
					path: '/refundlist'
				},
				{
					name: '兑换记录',
					path: '/exchangelist'
				},
			],
			userInfo: {},
			defaultpic: require('../assets/defaultpic.png'),
		}
	},
	methods: {
		goexplain () {
			this.$router.push({ path: '/explain', query: { articleId: '1' } });
		},
		getUserinfo () {
			this.$http.getUserinfo().then(res => {
				if (res.code == 200) {
					this.userInfo = res.data;
					this.$store.commit('setuserInfo', res.data)
				}
			}).catch(err => {
				console.log(err);
			});
		},
		getSystemInfo () {
			this.$http.getSystemInfo().then(res => {
				if (res.code == 200) {
					this.systemInfo = res.data;
				}
			}).catch(err => {
				console.log(err);
			});
		},
		refundApply () {
			this.$createDialog({
				type: 'confirm',
				//icon: 'cubeic-alert',
				title: '提示',
				content: this.systemInfo.refund_desc,
				confirmBtn: {
					text: '确定',
					active: true,
					disabled: false,
					href: 'javascript:;'
				},
				cancelBtn: {
					text: '取消',
					active: false,
					disabled: false,
					href: 'javascript:;'
				},
				onConfirm: () => {
					this.$http.refundApply().then(res => {
						if (res.code == 200) {
							this.$createDialog({
								type: 'alert',
								title: '提示',
								content: res.message,
							}).show()
						}
					}).catch(err => {
						console.log(err);
					});
				},
				onCancel: () => {

				}
			}).show()
		},
		gonext (item) {
			if (!item.path) {
				return false
			}
			this.$router.push({
				path: item.path
			});
		},
		gorecharge () {
			this.$router.push({
				path: '/recharge'
			});
		},
		/* async submitHandler(e) {
			e.preventDefault()
			try {
				const result = await this.$http.get('/api/login', {
					params: this.model
				})
				if (result.code == '0') {
					this.$store.commit('settoken', result.token)
					window.localStorage.setItem('token', result.token)
				} else {
					alert(result.message)
				}
			} catch (err) {
				console.log(err)
			}
		} */
	}
}
</script>

<style scoped lang="less">
.layindex {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	z-index: 9;

	.telbox {
		position: absolute;
		box-sizing: border-box;
		width: 57px;
		padding: 4px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		right: 10px;
		top: 10px;
		z-index: 10;
		background-color: #F9A709;
		border-radius: 20px;

		.icontel {
			width: 14px;
			height: 15px;
		}

		.teltext {
			font-size: 12px;
			color: #FFFFFF;
		}
	}

	.logoname {
		width: 98px;
		height: 22.5px;
		position: absolute;
		top: 13px;
		left: 15px;
		z-index: 10;
	}

	.minebox {
		width: 347px;
		box-sizing: border-box;
		padding: 7px;
		border-radius: 4px;
		background-color: rgba(255, 255, 255, 0.5);
		margin: 56px auto 0;
		overflow: hidden;

		.userinfo {
			width: 100%;
			box-sizing: border-box;
			padding: 15px 0;
			border-radius: 4px;
			overflow: hidden;
			position: relative;
			background: -moz-linear-gradient(top, #FFFFFF, #DFE8EF);
			background: -o-linear-gradient(top, #FFFFFF, #DFE8EF);
			background: -webkit-linear-gradient(top, #FFFFFF, #DFE8EF);
			/*浏览器的兼容*/
			background: linear-gradient(top, #FFFFFF, #DFE8EF);

			.refunddes {
				position: absolute;
				right: 10px;
				top: 13px;
				color: #5459AE;
				font-size: 11px;
				display: flex;
				align-items: center;

				.icon {
					width: 11px;
					height: 11px;
					vertical-align: text-bottom;
					margin-right: 4.5px;
				}
			}

			.useritem {
				width: 100%;
				display: flex;
				align-items: center;
				box-sizing: border-box;
				padding: 0 10px;

				.itembox {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.userpic {
						width: 60px;
						height: 60px;
						border-radius: 50%;
						overflow: hidden;
						margin-right: 13px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
						}
					}

					.userid {
						flex: 1;
						font-size: 15px;
						color: #2A2A2A;
						font-weight: bold;
					}

					.btnbox {
						width: 60px;
						height: 28px;
						border-radius: 28px;
						text-align: center;
						line-height: 29px;
						background-color: #F9A709;
						font-size: 12px;
						color: #FFFFFF;
						letter-spacing: 1px;
					}

					.btnbox:last-child {
						background-color: #D8D8D8;
					}
				}
			}

			.moneybox {
				width: 100%;
				box-sizing: border-box;
				margin-top: 10px;
				overflow: hidden;
				position: relative;
				display: flex;
				align-items: center;

				.item {
					flex: 1;
					text-align: center;
					box-sizing: border-box;
					padding: 12px 0 19px;
					border-right: 1px solid rgba(191, 191, 191, 0.6);

					.moneynum {
						color: #F9A709;
						font-size: 18px;
						letter-spacing: 1px;
						font-weight: bold;
					}

					.moneydes {
						color: #666666;
						font-size: 13px;
						line-height: 16px;
						margin-top: 10px;
						letter-spacing: 1px;
						text-align: center;
					}
				}

				.item:last-child {
					border: none;
				}
			}
		}

		.listul {
			width: 100%;
			overflow: hidden;
			margin-top: 13px;

			li {
				position: relative;
				box-sizing: border-box;
				padding: 16px 20px 16px 45px;
				background: -moz-linear-gradient(top, #FFFFFF, #DFE8EF);
				background: -o-linear-gradient(top, #FFFFFF, #DFE8EF);
				background: -webkit-linear-gradient(top, #FFFFFF, #DFE8EF);
				/*浏览器的兼容*/
				background: linear-gradient(top, #FFFFFF, #DFE8EF);
				font-size: 13px;
				color: #323232;
				letter-spacing: 1px;
				border-radius: 4px;
				margin-bottom: 8px;
			}

			li:last-child {
				margin-bottom: 2px;
			}

			li .icon {
				position: absolute;
				width: 17px;
				height: 19px;
				left: 15px;
				top: 50%;
				transform: translate(0, -50%);
			}

			li .iconarrow {
				position: absolute;
				width: 8px;
				height: 14px;
				right: 15px;
				top: 50%;
				transform: translate(0, -50%);
			}
		}
	}
}
</style>
